import React from 'react'
//Types
import { SectionBaseProps, SectionLogoWall } from '../../../types/sections'
//Components
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Box, Button, Flex, Heading, Image, Text, Link } from '../../../atoms'

export interface LogoWallProps extends SectionBaseProps {
  data: SectionLogoWall
}

const LogoWall: React.FC<LogoWallProps> = ({ data }) => {
  const { id, sectionId, headline, subtext, logos, sublink, logosByLine } = data
  return (
    <Box as={'section'} id={sectionId || id} className={'p-section'} my={[80, 80, 120]}>
      <Grid>
        <Box mb={[6, 8, 9]}>
          <Row>
            <Col xs={12}>
              <Heading textAlign={['left', 'center']} as={'h5'}>
                {headline}
              </Heading>
            </Col>
          </Row>
        </Box>
        <Row>
          <Col xs={12}>
            <Flex justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'} mx={['-6px', '-12px', '-18px']}>
              {logos.map((l) => {
                return (
                  <Box
                    flex={['0 0 25%', `0 0 ${100 / logosByLine}%`]}
                    key={l.id}
                    px={['6px', '12px', '18px']}
                    my={8 - logosByLine}
                    opacity={0.4}
                  >
                    {!!l.url ? (
                      <Link href={l.url} target={'_blank'} rel={'noopener noreferrer'} style={{ display: 'block' }}>
                        <Image draggable={false} alt={l.asset.alt || l.name} image={l.asset.gatsbyImageData} />
                      </Link>
                    ) : (
                      <Image draggable={false} alt={l.asset.alt || l.name} image={l.asset.gatsbyImageData} />
                    )}
                  </Box>
                )
              })}
            </Flex>
          </Col>
        </Row>
        {(!!subtext || !!sublink) && (
          <Box mt={[6, 8, 9]}>
            <Row>
              <Col xs={12}>
                <Flex justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'}>
                  {!!subtext && (
                    <Text textAlign={'center'} as={'span'}>
                      {subtext}
                    </Text>
                  )}
                  {!!sublink && (
                    <Box ml={!!subtext ? 2 : undefined}>
                      <Button {...sublink} />
                    </Box>
                  )}
                </Flex>
              </Col>
            </Row>
          </Box>
        )}
      </Grid>
    </Box>
  )
}
export default LogoWall
